import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ArtworksResponse } from 'models/artworks';

import { AppDispatch } from './index';

const initialState: ArtworksResponse = {
  data: [],
  pagination: {
    total: 0,
    current_page: 0,
    total_pages: 0,
    limit: 0,
    offset: 0,
  },
};

export const fetchArtworks =
  (
    getter: (page: number, itemsPerPage: number) => Promise<ArtworksResponse>,
    page = 1,
    itemsPerPage = 25
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await getter(page, itemsPerPage);
      dispatch(artworksResponseSlice.actions.replaceArtworksResponse(response));
    } catch (e) {
      throw new Error('Could not fetch data');
    }
  };

export const fetchArtworkSearch =
  (
    getter: (searchValue: string, page: number, itemsPerPage: number) => Promise<ArtworksResponse>,
    searchValue: string,
    page = 1,
    itemsPerPage = 25
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await getter(searchValue, page, itemsPerPage);
      dispatch(artworksResponseSlice.actions.replaceArtworksResponse(response));
    } catch (e) {
      throw new Error('Could not fetch data');
    }
  };

export const artworksResponseSlice = createSlice({
  name: 'artworksResponse',
  initialState,
  reducers: {
    replaceArtworksResponse(state, action: PayloadAction<ArtworksResponse>) {
      state.data = action.payload.data;
      state.pagination = action.payload.pagination;
    },
  },
});
