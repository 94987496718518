import { useCallback, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';

import { ArtworkCard } from 'components/ArtworkCard/ArtworkCard';
import { Paginator } from 'components/Paginator/Paginator';
import { useArtworksApi } from 'hooks/useArtworksApi';
import { useQuery } from 'hooks/useQuery';
import {
  useAppDispatch,
  useAppSelector,
  fetchArtworks,
  favouriteArtworksActions,
  fetchArtworkSearch,
  pageLoaderActions,
} from 'store';

export const ArtworkList = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const { getPaginatedArtworks, getSearchResults } = useArtworksApi();

  const artworksResponse = useAppSelector((state) => state.artworks);
  const favouriteArtworks = useAppSelector((state) => state.favouriteArtworks);
  const pageIsLoading = useAppSelector((state) => state.pageLoader);

  const pageChangeHandler = useCallback(
    async (page: number) => {
      if (!artworksResponse.pagination) return;
      dispatch(pageLoaderActions.showPageLoader());
      const searchQuery = query.get('q');
      if (!!searchQuery) {
        await dispatch(
          fetchArtworkSearch(getSearchResults, searchQuery, page, artworksResponse.pagination.limit)
        );
        dispatch(pageLoaderActions.hidePageLoader());
        return;
      }

      await dispatch(fetchArtworks(getPaginatedArtworks, page, artworksResponse.pagination.limit));
      dispatch(pageLoaderActions.hidePageLoader());
    },
    [artworksResponse.pagination, dispatch, getPaginatedArtworks, getSearchResults, query]
  );

  const itemsPerPageChangeHandler = useCallback(
    async (itemsPerPage: number) => {
      if (!artworksResponse.pagination) return;
      dispatch(pageLoaderActions.showPageLoader());
      const searchQuery = query.get('q');
      if (searchQuery) {
        await dispatch(
          fetchArtworkSearch(
            getSearchResults,
            searchQuery,
            artworksResponse.pagination.current_page,
            itemsPerPage
          )
        );
        dispatch(pageLoaderActions.hidePageLoader());
        return;
      }
      await dispatch(
        fetchArtworks(getPaginatedArtworks, artworksResponse.pagination.current_page, itemsPerPage)
      );
      dispatch(pageLoaderActions.hidePageLoader());
    },
    [artworksResponse.pagination, dispatch, getPaginatedArtworks, getSearchResults, query]
  );

  const handlePageLoad = useCallback(async () => {
    dispatch(pageLoaderActions.showPageLoader());
    const searchQuery = query.get('q');
    !!searchQuery
      ? await dispatch(fetchArtworkSearch(getSearchResults, searchQuery))
      : await dispatch(fetchArtworks(getPaginatedArtworks));
    dispatch(pageLoaderActions.hidePageLoader());
  }, [dispatch, getPaginatedArtworks, getSearchResults, query]);

  useEffect(() => {
    void handlePageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <section>
      {artworksResponse.pagination &&
        artworksResponse.pagination?.total > artworksResponse.pagination?.limit && (
          <Paginator
            pagination={artworksResponse.pagination}
            onChangePage={pageChangeHandler}
            onChangeItemsPerPage={itemsPerPageChangeHandler}
          />
        )}
      <Row style={{ gap: '20px 0px' }} className="justify-content-center">
        {artworksResponse?.data?.map((e) => (
          <Col xs="12" sm="6" md="4" lg="3" className="justify-content-between" key={e.id}>
            <ArtworkCard
              artwork={e}
              isFavourite={!!favouriteArtworks.find((artwork) => artwork.id === e.id)}
              onFavouriteButtonClick={() => dispatch(favouriteArtworksActions.handleArtwork(e))}
            />
          </Col>
        ))}
        {!pageIsLoading && !artworksResponse.data?.length && (
          <div className="fw-bold text-center fs-3">No results</div>
        )}
      </Row>
      {artworksResponse.pagination &&
        artworksResponse.pagination?.total > artworksResponse.pagination?.limit && (
          <Paginator
            pagination={artworksResponse.pagination}
            onChangePage={pageChangeHandler}
            onChangeItemsPerPage={itemsPerPageChangeHandler}
          />
        )}
    </section>
  );
};
