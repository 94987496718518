import { useCallback, useEffect, useState } from 'react';
import { Col, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';

import { FavouriteButton } from 'components/common/FavouriteButton/FavouriteButton';
import { DetailsTable } from 'components/DetailsTable/DetailsTable';
import { ImageModal } from 'components/modals/ImageModal';
import { useArtworksApi } from 'hooks/useArtworksApi';
import { Artwork, DetailedArtwork } from 'models/artworks';
import { favouriteArtworksActions, pageLoaderActions, useAppDispatch, useAppSelector } from 'store';

import './ArtworkDetails.scss';

export const ArtworkDetails = () => {
  const { artworkId } = useParams<{ artworkId: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getDetailedArtwork } = useArtworksApi();

  const favouriteArtworks = useAppSelector((state) => state.favouriteArtworks);

  const [artworkDetails, setArtworkDetails] = useState<DetailedArtwork | undefined>(undefined);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const detailedArtworkTransformer = useCallback(
    (artwork: DetailedArtwork) =>
      ({
        id: artwork.id,
        title: artwork.title,
        image_id: artwork.image_id,
      } as Artwork),
    []
  );

  const fetchArtwork = useCallback(async () => {
    if (!artworkId) return;
    dispatch(pageLoaderActions.showPageLoader());
    try {
      const response = await getDetailedArtwork(artworkId);
      setArtworkDetails(response.data);
    } catch {
      navigate('/');
      throw new Error('Could not fetch data!');
    } finally {
      dispatch(pageLoaderActions.hidePageLoader());
    }
  }, [artworkId, dispatch, getDetailedArtwork, navigate]);

  useEffect(() => {
    void fetchArtwork();
  }, [fetchArtwork]);

  return (
    <>
      <Container className="justify-content-center text-center artwork-details">
        {artworkDetails && (
          <>
            <Col lg="9" xl="8" className="mx-auto">
              <h1>
                {artworkDetails.title}
                <FavouriteButton
                  hasOutline
                  onClick={() =>
                    dispatch(
                      favouriteArtworksActions.handleArtwork(
                        detailedArtworkTransformer(artworkDetails)
                      )
                    )
                  }
                  isFilled={!!favouriteArtworks.find((e) => e.id === artworkDetails.id)}
                />
              </h1>
            </Col>
            {artworkDetails.artist_display && (
              <p className="artwork-details__artist">{artworkDetails.artist_display}</p>
            )}
            {artworkDetails.image_id && (
              <img
                src={`https://www.artic.edu/iiif/2/${
                  artworkDetails.image_id || ''
                }/full/600,/0/default.jpg`}
                alt={artworkDetails?.thumbnail?.alt_text}
                className="artwork-details__image"
                onClick={() => setIsImageModalOpen(true)}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.style.display = 'none';
                }}
              />
            )}
            <DetailsTable details={artworkDetails} />
          </>
        )}
      </Container>
      {artworkDetails?.image_id && isImageModalOpen && (
        <ImageModal
          onClose={() => setIsImageModalOpen(false)}
          imageId={artworkDetails.image_id}
          altText={artworkDetails?.thumbnail?.alt_text}
        />
      )}
    </>
  );
};
