import { useCallback } from 'react';
import { Col, Container, Navbar, Nav, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { SearchInput } from 'components/SearchInput/SearchInput';
import { useArtworksApi } from 'hooks/useArtworksApi';
import ArtworksLogo from 'images/artworks-logo.svg';
import { fetchArtworks, fetchArtworkSearch, pageLoaderActions, useAppDispatch } from 'store';

import 'components/layout/header.scss';

export const Header = () => {
  const dispatch = useAppDispatch();
  const { getPaginatedArtworks, getSearchResults } = useArtworksApi();

  const searchHandler = useCallback(
    async (searchValue: string) => {
      dispatch(pageLoaderActions.showPageLoader());
      await dispatch(fetchArtworkSearch(getSearchResults, searchValue));
      dispatch(pageLoaderActions.hidePageLoader());
    },
    [dispatch, getSearchResults]
  );

  const handleBrandClick = useCallback(async () => {
    dispatch(pageLoaderActions.showPageLoader());
    await dispatch(fetchArtworks(getPaginatedArtworks));
    dispatch(pageLoaderActions.hidePageLoader());
  }, [dispatch, getPaginatedArtworks]);

  return (
    <div className="header">
      <Navbar bg="dark" variant="dark" className="header__navbar">
        <Container>
          <Col xs="6">
            <Row>
              <Col xs="4" md="8">
                <Link to="/artworks" onClick={handleBrandClick}>
                  <Navbar.Brand className="pe-0">
                    <img
                      alt="Artworks"
                      src={ArtworksLogo}
                      width="30"
                      height="30"
                      className="d-inline-block align-top"
                    />
                    <span className="ms-3 header__title">Artworks</span>
                  </Navbar.Brand>
                </Link>
              </Col>
              <Col xs="8" md="4" className="align-self-center">
                <Nav.Item>
                  <Nav.Link
                    to="/favourites"
                    as={Link}
                    className="text-decoration-none text-white fw-bolder"
                    data-cy="favourites-page-link"
                  >
                    Favourites
                  </Nav.Link>
                </Nav.Item>
              </Col>
            </Row>
          </Col>
          <Col xs="6" md="5" lg="4" xl="3">
            <SearchInput onSearch={(searchValue) => searchHandler(searchValue)} />
          </Col>
        </Container>
      </Navbar>
    </div>
  );
};
