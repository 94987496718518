import { Modal } from 'components/common/Modal/Modal';

import './PageLoaderModal.scss';

export const PageLoaderModal = () => (
  <Modal>
    <div className="page-loader">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </Modal>
);
