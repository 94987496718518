import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import { ArtworkDetails } from 'pages/ArtworkDetails/ArtworkDetails';
import { ArtworkList } from 'pages/ArtworkList';
import { FavouriteArtworks } from 'pages/FavouriteArtworks';

export const Routes = () => (
  <Switch>
    <Route path="/artworks" element={<ArtworkList />} />
    <Route path="/favourites" element={<FavouriteArtworks />} />
    <Route path="/artwork/:artworkId" element={<ArtworkDetails />} />
    <Route path="*" element={<Navigate to="/artworks" replace />} />
    <Route path="/artwork/:artworkId" element={<ArtworkDetails />} />
  </Switch>
);
