import { FC } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import { FavouriteButton } from 'components/common/FavouriteButton/FavouriteButton';
import artworksLogo from 'images/artworks-logo.svg';
import { Artwork } from 'models/artworks';

import 'components/ArtworkCard/ArtworkCard.scss';

interface ArtworkCardProps {
  artwork: Artwork;
  isFavourite: boolean;
  onFavouriteButtonClick: () => void;
}

export const ArtworkCard: FC<ArtworkCardProps> = ({
  artwork,
  isFavourite,
  onFavouriteButtonClick,
}) => {
  const navigate = useNavigate();

  return (
    <Card className="artwork-card" bg="dark" data-cy="artwork-card">
      <Card.Img
        variant="top"
        className="artwork-card__image"
        src={`https://www.artic.edu/iiif/2/${artwork.image_id}/full/400,/0/default.jpg`}
        onClick={() => navigate(`/artwork/${artwork.id}`)}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src = artworksLogo;
        }}
      />
      <Card.Body>
        <Card.Title className="artwork-card__title text-white" data-cy="artwork-card-title">
          {artwork.title}
        </Card.Title>
        <Row className="justify-content-between">
          <Col>
            <Button
              variant="secondary"
              onClick={() => navigate(`/artwork/${artwork.id}`)}
              data-cy="details-button"
            >
              Details
            </Button>
          </Col>
          <Col className="text-end">
            <FavouriteButton onClick={onFavouriteButtonClick} isFilled={isFavourite} />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
