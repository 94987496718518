import { Children, FC } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';

interface ItemsPerPageSelectProps {
  active: number;
  options: number[];
  onSelect: (count: number) => void;
}

export const ItemsPerPageSelect: FC<ItemsPerPageSelectProps> = ({ active, options, onSelect }) => (
  <DropdownButton
    as={ButtonGroup}
    variant="secondary"
    title={active}
    data-cy="items-per-page-select"
  >
    {Children.toArray(
      options.map((e, index) => (
        <Dropdown.Item eventKey={index + 1} active={active === e} onClick={() => onSelect(e)}>
          {e}
        </Dropdown.Item>
      ))
    )}
  </DropdownButton>
);
