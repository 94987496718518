import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Artwork } from 'models/artworks';

const initialState: Artwork[] = [];

export const favouriteArtworksSlice = createSlice({
  name: 'favouriteArtworks',
  initialState,
  reducers: {
    handleArtwork(state, action: PayloadAction<Artwork>) {
      const isFavourite = !!state.find((e) => e.id === action.payload.id);
      if (isFavourite) return (state = state.filter((artwork) => artwork.id !== action.payload.id));
      state.push(action.payload);
    },
  },
});

export const favouriteArtworksActions = favouriteArtworksSlice.actions;
