import { createSlice } from '@reduxjs/toolkit';

const initialState = false;

export const pageLoaderSlice = createSlice({
  name: 'pageIsLoading',
  initialState,
  reducers: {
    showPageLoader() {
      return true;
    },
    hidePageLoader() {
      return false;
    },
  },
});

export const pageLoaderActions = pageLoaderSlice.actions;
