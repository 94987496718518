import { FC } from 'react';

import './Modal.scss';

interface ModalProps {
  children: JSX.Element;
  onClose?: () => void;
}

export const Modal: FC<ModalProps> = ({ onClose, children }) => (
  <div className={`custom-modal ${onClose ? 'custom-modal__closable' : ''}`} onClick={onClose}>
    <div className="custom-modal__content-container">
      {onClose && (
        <div className="custom-modal__close-button">
          <span className="icon-close" />
        </div>
      )}
      <div onClick={(e) => e.stopPropagation()} className="custom-modal__content">
        {children}
      </div>
    </div>
  </div>
);
