import { FC } from 'react';
import { Col, Row } from 'react-bootstrap';

import { DetailedArtwork } from 'models/artworks';

import './DetailsTable.scss';

interface DetailsTableProps {
  details: DetailedArtwork;
}

export const DetailsTable: FC<DetailsTableProps> = ({ details }) => {
  const {
    main_reference_number,
    place_of_origin,
    date_end,
    medium_display,
    dimensions,
    department_title,
    credit_line,
  } = details;

  const handleData = (data?: string) => data || '-';

  return (
    <Col className="artwork-details__table mx-auto mt-5 text-start" md="6">
      <Row>
        <Col xs="4">Place</Col>
        <Col xs="8">{handleData(place_of_origin)}</Col>
      </Row>
      <hr />
      <Row>
        <Col xs="4">Department</Col>
        <Col xs="8">{handleData(department_title)}</Col>
      </Row>
      <hr />
      <Row>
        <Col xs="4">Date</Col>
        <Col xs="8">{handleData(date_end)}</Col>
      </Row>
      <hr />
      <Row>
        <Col xs="4">Dimensions</Col>
        <Col xs="8">{handleData(dimensions)}</Col>
      </Row>
      <hr />
      <Row>
        <Col xs="4">Medium</Col>
        <Col xs="8">{handleData(medium_display)}</Col>
      </Row>
      <hr />
      <Row>
        <Col xs="4">Credit line</Col>
        <Col xs="8">{handleData(credit_line)}</Col>
      </Row>
      <hr />
      <Row>
        <Col xs="4">Reference number</Col>
        <Col xs="8">{handleData(main_reference_number)}</Col>
      </Row>
    </Col>
  );
};
