import { useCallback } from 'react';

import { useApi } from 'hooks/useApi';
import { ArtworksResponse, SingleArtworkResponse } from 'models/artworks';

export const useArtworksApi: () => {
  getPaginatedArtworks: (page: number, itemsPerPage: number) => Promise<ArtworksResponse>;
  getSearchResults: (
    searchValue: string,
    page: number,
    itemsPerPage: number
  ) => Promise<ArtworksResponse>;
  getDetailedArtwork: (artworkId: string) => Promise<SingleArtworkResponse>;
} = () => {
  const { sendGet } = useApi();

  const getPaginatedArtworks = useCallback(
    async (page: number, itemsPerPage: number) =>
      sendGet<ArtworksResponse>(`?page=${page}&limit=${itemsPerPage}&fields=id,title,image_id`),
    [sendGet]
  );

  const getSearchResults = useCallback(
    async (searchValue: string, page: number, itemsPerPage: number) =>
      sendGet<ArtworksResponse>(
        `/search?q=${searchValue}&page=${page}&limit=${itemsPerPage}&fields=id,title,image_id`
      ),
    [sendGet]
  );

  const getDetailedArtwork = useCallback(
    async (artworkId: string) =>
      sendGet<SingleArtworkResponse>(
        `/${artworkId}?fields=id,title,artist_display,image_id,thumbnail,department_title,place_of_origin,date_end,medium_display,dimensions,credit_line,main_reference_number`
      ),
    [sendGet]
  );

  return {
    getPaginatedArtworks,
    getSearchResults,
    getDetailedArtwork,
  };
};
