import { useCallback } from 'react';

interface Api {
  sendGet: <ResultType>(url: string, options?: RequestInit) => Promise<ResultType>;
}

const baseUrl = 'https://api.artic.edu/api/v1/artworks';

export const useApi = (): Api => {
  const sendGet = useCallback(
    async <ResultType>(url: string, options?: RequestInit): Promise<ResultType> => {
      const response = await fetch(`${baseUrl}${url}`, { method: 'GET', ...options });
      return (await response.json()) as Promise<ResultType>;
    },
    []
  );
  return { sendGet };
};
