import { FC, useCallback, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

import './SearchInput.scss';

interface SearchInputProps {
  onSearch: (value: string) => void;
}

export const SearchInput: FC<SearchInputProps> = ({ onSearch }) => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  const handleSearch = useCallback(() => {
    if (searchValue.length < 3) return setIsInvalid(true);
    setIsInvalid(false);
    navigate(`/artworks?q=${searchValue}`);
    onSearch(searchValue);
    setSearchValue('');
  }, [navigate, onSearch, searchValue]);

  return (
    <InputGroup>
      <Form.Control
        value={searchValue}
        size="sm"
        onChange={(e) => setSearchValue(e.target.value)}
        aria-label="Search value"
        onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
        isInvalid={isInvalid}
        data-cy="search-input"
      />

      <InputGroup.Text className="search-button" onClick={handleSearch}>
        <span className="icon-search" />
      </InputGroup.Text>
    </InputGroup>
  );
};
