import { FC } from 'react';

import { Modal } from 'components/common/Modal/Modal';

interface ImageModal {
  onClose: () => void;
  imageId: string;
  altText?: string;
}

export const ImageModal: FC<ImageModal> = ({ onClose, imageId, altText }) => (
  <Modal onClose={onClose}>
    <img src={`https://www.artic.edu/iiif/2/${imageId}/full/1686,/0/default.jpg`} alt={altText} />
  </Modal>
);
