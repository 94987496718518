import { Container } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';

import { ScrollToTop } from 'components/common/ScrollToTop';
import { Header } from 'components/layout/Header';
import { PageLoaderModal } from 'components/modals/PageLoaderModal/PageLoaderModal';
import { Routes } from 'pages/Routes';
import { useAppSelector } from 'store';

export const App = () => {
  const pageIsLoading = useAppSelector((state) => state.pageLoader);
  return (
    <>
      {pageIsLoading && <PageLoaderModal />}
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <Container className="mt-5">
          <Routes />
        </Container>
      </BrowserRouter>
    </>
  );
};
