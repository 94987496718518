import { FC } from 'react';
import { Pagination, Row, Col } from 'react-bootstrap';

import { ItemsPerPageSelect } from 'components/Paginator/ItemsPerPageSelect';
import { ArtworksPagination } from 'models/artworks';

import './Paginator.scss';

interface PaginatorProps {
  pagination: ArtworksPagination;
  onChangePage: (page: number) => void;
  onChangeItemsPerPage: (itemsPerPage: number) => void;
}

export const Paginator: FC<PaginatorProps> = ({
  pagination,
  onChangePage,
  onChangeItemsPerPage,
}) => (
  <Row className="paginator">
    <Col>
      <ItemsPerPageSelect
        active={pagination.limit}
        options={[5, 10, 15, 20, 25]}
        onSelect={onChangeItemsPerPage}
      />
    </Col>
    <Col>
      <Pagination className="justify-content-end">
        <Pagination.Prev
          disabled={pagination.current_page === 1}
          onClick={() => onChangePage(pagination.current_page - 1)}
        />

        {pagination.current_page > 2 && (
          <Pagination.Item onClick={() => onChangePage(pagination.current_page - 2)}>
            {pagination.current_page - 2}
          </Pagination.Item>
        )}

        {pagination.current_page > 1 && (
          <Pagination.Item onClick={() => onChangePage(pagination.current_page - 1)}>
            {pagination.current_page - 1}
          </Pagination.Item>
        )}

        <Pagination.Item active>{pagination.current_page}</Pagination.Item>

        {pagination.current_page !== pagination.total_pages && (
          <Pagination.Item onClick={() => onChangePage(pagination.current_page + 1)}>
            {pagination.current_page + 1}
          </Pagination.Item>
        )}

        {pagination.current_page === pagination.total_pages ||
          (pagination.current_page !== pagination.total_pages - 1 && (
            <Pagination.Item onClick={() => onChangePage(pagination.current_page + 2)}>
              {pagination.current_page + 2}
            </Pagination.Item>
          ))}

        <Pagination.Next
          onClick={() => onChangePage(pagination.current_page + 1)}
          disabled={pagination.current_page === pagination.total_pages}
          data-cy="paginator-next-page"
        />
      </Pagination>
    </Col>
  </Row>
);
