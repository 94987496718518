import { FC } from 'react';

import './FavouriteButton.scss';

interface FavouriteButtonProps {
  onClick: () => void;
  isFilled: boolean;
  hasOutline?: boolean;
}

export const FavouriteButton: FC<FavouriteButtonProps> = ({ onClick, isFilled, hasOutline }) => (
  <button className="favourite-button" onClick={onClick} data-cy="favourite-button">
    <svg viewBox="0 0 1554 1477" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M777 56.6312L943.56 569.25L947.489 581.342H960.203L1499.2 581.342L1063.14 898.158L1052.86 905.631L1056.79 917.724L1223.35 1430.34L787.286 1113.53L777 1106.05L766.714 1113.53L330.654 1430.34L497.214 917.724L501.143 905.631L490.857 898.158L54.7973 581.342L593.797 581.342H606.511L610.44 569.25L777 56.6312Z"
        fill={isFilled ? '#f2fa1b' : 'transparent'}
        stroke="#f2fa1b"
        strokeWidth="100"
      />
      {hasOutline && (
        <path
          d="M777 56.6312L943.56 569.25L947.489 581.342H960.203L1499.2 581.342L1063.14 898.158L1052.86 905.631L1056.79 917.724L1223.35 1430.34L787.286 1113.53L777 1106.05L766.714 1113.53L330.654 1430.34L497.214 917.724L501.143 905.631L490.857 898.158L54.7973 581.342L593.797 581.342H606.511L610.44 569.25L777 56.6312Z"
          stroke="#a28b8bd1"
          strokeWidth="100"
        />
      )}
    </svg>
  </button>
);
