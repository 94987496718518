import { Col, Row } from 'react-bootstrap';

import { ArtworkCard } from 'components/ArtworkCard/ArtworkCard';
import { favouriteArtworksActions, useAppDispatch, useAppSelector } from 'store';

export const FavouriteArtworks = () => {
  const dispatch = useAppDispatch();

  const favouriteArtworks = useAppSelector((state) => state.favouriteArtworks);

  return (
    <Row style={{ gap: '20px 0px' }} className="justify-content-center">
      {favouriteArtworks.length ? (
        favouriteArtworks.map((e) => (
          <Col xs="12" sm="6" md="4" lg="3" className="justify-content-between" key={e.id}>
            <ArtworkCard
              onFavouriteButtonClick={() => dispatch(favouriteArtworksActions.handleArtwork(e))}
              isFavourite
              artwork={e}
            />
          </Col>
        ))
      ) : (
        <div className="fw-bold text-center fs-3">
          You haven't picked any favourite artworks yet.
        </div>
      )}
    </Row>
  );
};
